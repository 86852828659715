<template>
  <section v-editable="blok" :class="sectionClasses" class="">
    <div
      :class="{
        'container inside h-full': !blok.full_width && !blok.blocks_full_width,
        relative: hasVideoBackground,
      }"
    >
      <VideoBackground
        v-if="hasVideoBackground && breakpoint.lgAndUp"
        :blok="{
          ...blok.video_background[0],
          layout: blok.layout,
          padding: breakpoint.xl
            ? paddings['xl']
            : breakpoint.lgAndUp
              ? paddings['lg']
              : paddings['default'],
        }"
      />
      <slot />
      <StoryblokComponent
        v-for="blok in blok.blocks"
        :key="blok._uid"
        :blok="blok"
      />
      <VideoBackground
        v-if="hasVideoBackground && !breakpoint.lgAndUp"
        :blok="{
          ...blok.video_background[0],
          layout: blok.layout,
          padding: breakpoint.lgAndUp ? '100px' : '50px',
        }"
      />
    </div>
  </section>
</template>

<script setup>
const { breakpoint } = useUtils();
const props = defineProps({ blok: Object });

const paddings = {
  default: "100px",
  lg: "150px",
  xl: "200px",
};
const hasVideoBackground = computed(
  () => props.blok.video_background && props.blok.video_background.length === 1,
);
const sectionClasses = computed(() => {
  return {
    section: !props.blok.full_width,
    "py-[40px]":
      (!props.blok.padding_y && props.blok.small) ||
      props.blok.padding_y === "sm",
    "py-[50px] md:py-[100px]":
      (!props.blok.padding_y && !props.blok.small) ||
      props.blok.padding_y === "default",
    "py-[50px] md:py-[150px]":
      props.blok.padding_y && props.blok.padding_y === "lg",
    "py-[50px] md:py-[200px]":
      props.blok.padding_y && props.blok.padding_y === "xl",
    "":
      props.blok.background &&
      (!props.blok.background_color || props.blok.background_color === "gray"),
    "bg-gray-gradient text-white":
      props.blok.background && props.blok.background_color === "gray-gradient",
    "bg-primary gardient-primary text-white":
      props.blok.background && props.blok.background_color === "primary",
    "bg-secondary text-white":
      props.blok.background && props.blok.background_color === "secondary",
    // "overflow-visible": props.blok.image_layout === "overlapping",
    // "overflow-hidden": props.blok.image_layout !== "overlapping",
  };
});
</script>

<style lang="scss" scoped>
.section {
  display: block;
}
</style>
